import IWebSocketProvider from 'shared/domain/providers/IWebSocketProvider';
import makeHTTPProvider from 'shared/infra/providers/makeHTTPProvider';

import IOWebSocketProvider from './implementations/IOWebSocketProvider';

let instance: IWebSocketProvider | null = null;

const url = process.env.REACT_APP_WEBSOCKET_URL;

type TParams = { unitId?: number };

export default function makeWebSocketProvider({
  unitId,
}: TParams = {}): IWebSocketProvider {
  if (!instance) {
    const httpProvider = makeHTTPProvider({ source: 'METRICS' });

    instance = new IOWebSocketProvider({
      url: url || '',
      httpProvider,
      unitId,
    });
  }

  return instance;
}
