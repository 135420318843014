import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    background-color: ${theme.palette.neutral[0]};
    border-radius: 2rem;

    padding: ${theme.layout.spacing(4)};
    ${theme.layout.rowGap(4)}

    footer {
      display: flex;

      > button {
        flex: 1;
      }
    }
  `}
`;
