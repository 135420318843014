import React, { useEffect, useState } from 'react';

import fingerprint from 'assets/images/tela-totem-digital-icon.png';
import { makeCustomerBiometryPubsub } from 'shared/infra/pubsubs';
import { Button } from 'shared/presentation/components/molecules';
import { useLocale, useTotemInfo } from 'shared/presentation/contexts';

import { useFetchBiometry } from './hooks';
import * as S from './styles';

interface IWaitingBiometryPanelProps {
  customerId: number;
  onGoBack(): void;
  onConfirm(): void;
}

const WaitingBiometryPanel: React.FC<IWaitingBiometryPanelProps> = ({
  customerId,
  onGoBack,
  onConfirm,
}) => {
  const [warningMessage, setWarningMessage] = useState('');
  const { unitId } = useTotemInfo();

  const { t } = useLocale('translations');
  const controller = useFetchBiometry({
    customerId,
    onSuccess: onConfirm,
  });

  useEffect(() => {
    const pubsub = makeCustomerBiometryPubsub({ unitId });

    const unsubscribe = pubsub.subscribeForBiometryErrorMessages(
      ({ event }) => {
        if (event === 'NOT_CONFIRMED') {
          setWarningMessage(t('pages.checkin.update.not_confirmed_warning'));
        }
      },
    );

    return () => {
      unsubscribe();
    };
  }, [t, unitId]);

  const header = (
    <header>
      <h1>{t('pages.checkin.update.title')}</h1>

      <p>{t('pages.checkin.update.biometry')}</p>
    </header>
  );

  const goBackButton = (
    <Button
      onClick={() => {
        setWarningMessage('');
        onGoBack();
      }}
      variant="primary"
      outline
    >
      {t('components.modal.actions.cancel')}
    </Button>
  );

  if (controller.loading) {
    return (
      <S.Container>
        {header}

        <S.FingerprintImage src={fingerprint} />

        <footer>
          {!!warningMessage && <p>{warningMessage}</p>}

          {goBackButton}
        </footer>
      </S.Container>
    );
  }

  return (
    <S.Container>
      {header}

      <footer>
        {!!controller.error && <p>{controller.error}</p>}

        <Button
          onClick={() => {
            setWarningMessage('');
            controller.getBiometry();
          }}
          variant="primary"
        >
          {t('pages.checkin.update.try_gain')}
        </Button>

        {goBackButton}
      </footer>
    </S.Container>
  );
};

export default WaitingBiometryPanel;
