import { useState, useEffect } from 'react';

import { TLocale, TTotemVersion } from 'shared/domain/valueObjects';
import {
  makeHashProvider,
  makeWebSocketProvider,
} from 'shared/infra/providers';
import { makeTotemInfoPubsub } from 'shared/infra/pubsubs';
import { useRouteQuery } from 'shared/presentation/contexts/NavigationContext';

import { TotemInfoContext } from '../../hooks/useTotemInfo';

export type TTenancy = 'vialaser' | 'vialaserparaguay' | 'vialasercostarica';

interface IHashData {
  unidade?: number;
  tenancia?: TTenancy;
  versaoTotem?: '1';
}

const LOCALE_BY_TENANCY: Record<TTenancy, TLocale> = {
  vialaser: 'pt',
  vialaserparaguay: 'es',
  vialasercostarica: 'es-CR',
};

const TotemInfoProvider: React.FC = ({ children }) => {
  const { hash } = useRouteQuery<'/'>();

  const [unitId, setUnitId] = useState<number | undefined>();
  const [language, setLanguage] = useState<TLocale>();
  const [version, setVersion] = useState<TTotemVersion>();

  useEffect(() => {
    const infoPubSub = makeTotemInfoPubsub();

    const unsubscribe = infoPubSub.subscribeForTotemSpecifications(
      async ({ language, unitId }) => {
        setUnitId(unitId);
        makeWebSocketProvider().setUnit(unitId);
        setLanguage(language);
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!hash) return;

    try {
      const { unidade, tenancia, versaoTotem } =
        makeHashProvider().decode<IHashData>(hash);

      if (unidade) setUnitId(unidade);
      if (tenancia) {
        setLanguage(LOCALE_BY_TENANCY[tenancia]);
      }
      setVersion(versaoTotem || 'legacy');
    } catch {}
  }, [hash, version]);

  return (
    <TotemInfoContext.Provider
      value={{
        unitId,
        language,
        version,
      }}
    >
      {children}
    </TotemInfoContext.Provider>
  );
};

export default TotemInfoProvider;
