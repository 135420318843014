import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.layout.spacing(4)};
    ${theme.layout.rowGap(6)}

    background-color: ${theme.palette.neutral[0]};
    border-radius: 2rem;

    width: 670px;

    color: ${theme.palette.text.main};

    text-align: center;

    h1 {
      font-size: ${theme.typography.sizes.title2};
      font-weight: ${theme.typography.weight.bold};
      color: ${theme.palette.primary.dark};
    }

    p {
      font-size: ${theme.typography.sizes.body1};
      color: ${theme.palette.text.light};
    }
  `}
`;
