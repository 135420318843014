import { useEffect, useRef } from 'react';

import { useModal } from 'context/ModalContext';

import { makeCheckinPubSub } from 'shared/infra/pubsubs';
import { useTotemInfo } from 'shared/presentation/contexts';

type TParams = {
  onFailedTwice(): void;
};

export default function useBiometryFailure({ onFailedTwice }: TParams) {
  const madeAttempt = useRef(false);
  const { showModal } = useModal();
  const { version, unitId } = useTotemInfo();

  useEffect(() => {
    const unsubscribe = makeCheckinPubSub({
      type: version,
      unitId,
    }).subscribeForFailure(() => {
      if (!madeAttempt.current) {
        madeAttempt.current = true;
        showModal({ type: 'BIOMETRIA' });
        return;
      }

      madeAttempt.current = false;
      onFailedTwice();
    });

    return () => {
      unsubscribe();
    };
  }, [onFailedTwice, version, showModal, unitId]);
}
