import React from 'react';

import { useLocale } from 'shared/presentation/contexts';

import StepContainer from '../StepContainer';
import businessImage from './assets/business.jpg';
import moneyImage from './assets/money.jpg';

const FourthStep: React.FC = () => {
  const { t, language } = useLocale('checkin');

  const isParaguay = language === 'es';

  return (
    <StepContainer>
      <h1>{t('termsReminder.content.title')}</h1>

      <div>
        <h2>{t('termsReminder.content.secondSection.title')}</h2>

        <ul>
          {!isParaguay && (
            <li>
              <img src={businessImage} alt="" />

              <div>
                <p>
                  <strong>
                    {t(
                      'termsReminder.content.secondSection.cancellation.title',
                    )}
                  </strong>
                </p>

                <span>
                  {t('termsReminder.content.secondSection.cancellation.text')}
                </span>
              </div>
            </li>
          )}

          <li>
            <img src={moneyImage} alt="" />

            <div>
              <p>
                <strong>
                  {t('termsReminder.content.secondSection.clause.title')}
                </strong>
              </p>

              <span>
                {t('termsReminder.content.secondSection.clause.text')}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </StepContainer>
  );
};

export default FourthStep;
