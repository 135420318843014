export const termsReminder = {
  title: 'Recuerda nuestros términos',
  content: {
    title: 'Recuerda los términos con Lisa!',
    firstSection: {
      title: 'Para el éxito de su tratamiento',
      panel1: {
        description:
          'Para garantizar el mejor resultado posible, es fundamental seguir cuidadosamente los <1>cuidados previos a la sesión</1>:',
        hydration:
          '<0>Hidratación:</0> Mantén tu piel bien hidratada los días previos a la sesión.',
        hair: '<0>Recorta tu vello:</0> Recorta tu vello 2 a 3 días antes de la sesión, según las indicaciones, con una navaja o crema depilatoria.',
        exposure:
          '<0>Evite la exposición al sol:</0> Evite la exposición al sol al menos 15 días antes de la sesión.',
      },
      schedule: {
        title: 'Agenda tus sesiones',
        text: 'Vialaser está presente en todo Brasil y sus sesiones pueden realizarse en cualquiera de nuestras tiendas, siempre que sean programadas previamente. Recomendamos reservar las sesiones con antelación para asegurar tu cita y asegurar la continuidad del tratamiento, consiguiendo los mejores resultados posibles. Recuerda siempre programar la siguiente cuando termines tu sesión.',
      },
      treatment: {
        title: 'Resultado esperado del tratamiento',
        text: 'Cada cuerpo responde de forma única al tratamiento, por lo que la comunicación entre el cliente y el profesional es fundamental. Infórmanos siempre de los resultados de las sesiones para poder ajustar las potencias del equipo. Recuerde que el uso de medicamentos, tratamientos médicos y ciertas condiciones de salud pueden impactar directamente en los resultados del tratamiento. Si tienes alguna duda, habla con la aplicadora de tu sesión.',
      },
    },
    secondSection: {
      title: 'Para una buena relación',
      contract: {
        title: 'Firma de Contrato',
        text: 'La compra del producto se realiza mediante un contrato, que será enviado al correo electrónico facilitado y podrá solicitarse en cualquier momento. Es importante resaltar que el pago de las cuotas es obligatorio, independientemente de que se realicen las sesiones.',
      },
      serasa: {
        title: 'Registro en SERASA',
        text: 'El compromiso asumido en el contrato deberá cumplirse, independientemente de que se celebren las sesiones. No hacerlo puede resultar en la inscripción en servicios de protección de crédito.',
      },
      cancellation: {
        title: 'Cancelación de Contrato',
        text: 'El consumidor puede solicitar la cancelación del contrato por cualquier medio de contacto, ya sea presencial o virtual. Luego de la solicitud, se enviará un correo electrónico para confirmar la identidad e intención de cancelar, conteniendo un botón para completar el proceso. Una vez que el consumidor apruebe la cancelación, se enviará la cancelación y los importes relacionados con el incumplimiento del contrato.',
      },
      clause: {
        title: 'Cláusula de Retención del 30% del Valor Remanente',
        text: 'El contrato prevé expresamente la retención del 30% del importe correspondiente a la parte incumplida. El pago o reembolso se determinará en función del monto ya pagado por el consumidor, teniendo en cuenta las sesiones perdidas y las sesiones canceladas.',
      },
    },
    success: {
      title: 'Gracias por la confirmación!',
      text: 'Gracias por leer nuestros términos nuevamente. Esperamos que nuestra relación sea siempre la mejor posible!',
    },
    notConfirmed: {
      title: 'Confirmamos los términos en otro momento',
      text: 'No pudimos confirmar los términos de su contrato, pero está todo bien. Podemos realizar la confirmación de los términos en su próximo check-in!',
    },
  },
  actions: {
    back: 'Volver',
    home: 'Volver arriba',
    next: 'Próximo',
    understood: 'Entendí',
    continue: 'Continuar el check-in',
  },
  biometry: {
    text: 'Utilice el <1>lector biométrico</1> para confirmar que comprende y acepta los términos',
    use_code: 'Confirmar via código',
  },
  code: {
    title: 'Elija por donde desea recibir el código de confirmación',
    use_biometry: 'Confirmar via biometría',
    choose_another: 'Elegir otra opción de envío',
    email: 'Correo electrónico',
    placeholder: 'Código de validación',
    resend: 'Enviar de nuevo',
    resend_in: 'Enviar de nuevo en {{count}}s',
    valid_for_one: 'El código sólo es válido durante {{count}} minuto.',
    valid_for_other: 'El código sólo es válido durante {{count}} minutos.',
    time_up: '* El tiempo se terminó, envíe otro código para continuar.',
    validate: 'Validar código',
  },
};
