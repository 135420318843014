import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import { TLocale } from 'shared/domain/valueObjects';

import AxiosMetricsHTTPProvider from './implementations/AxiosMetricsHTTPProvider';
import AxiosVectisHTTPProvider from './implementations/AxiosVectisHTTPProvider';

type TParams = {
  source?: 'VECTIS' | 'METRICS';
  language?: TLocale;
};

const IMPLEMENTATIONS = {
  VECTIS: AxiosVectisHTTPProvider,
  METRICS: AxiosMetricsHTTPProvider,
};

const instances = new Map<TParams['source'], IHTTPProvider>();

export default function makeHTTPProvider({
  source = 'VECTIS',
  language = 'pt',
}: TParams = {}): IHTTPProvider {
  if (!instances.has(source)) {
    const Implementation = IMPLEMENTATIONS[source];

    instances.set(source, new Implementation(language));
  }

  return instances.get(source)!;
}
