import React from 'react';

import { useLocale } from 'shared/presentation/contexts';

import StepContainer from '../StepContainer';
import contractImg from './assets/contract.jpg';
import serasaImage from './assets/serasa.jpg';

const ThirdStep: React.FC = () => {
  const { t } = useLocale('checkin');

  return (
    <StepContainer>
      <h1>{t('termsReminder.content.title')}</h1>

      <div>
        <h2>{t('termsReminder.content.secondSection.title')}</h2>

        <ul>
          <li>
            <img src={contractImg} alt="" className="bordered" />

            <div>
              <p>
                <strong>
                  {t('termsReminder.content.secondSection.contract.title')}
                </strong>
              </p>

              <span>
                {t('termsReminder.content.secondSection.contract.text')}
              </span>
            </div>
          </li>

          <li>
            <img src={serasaImage} alt="" className="bordered" />

            <div>
              <p>
                <strong>
                  {t('termsReminder.content.secondSection.serasa.title')}
                </strong>
              </p>

              <span>
                {t('termsReminder.content.secondSection.serasa.text')}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </StepContainer>
  );
};

export default ThirdStep;
