export const termsReminder = {
  title: 'Relembre nossos termos',
  content: {
    title: 'Relembre os termos com a Lisa!',
    firstSection: {
      title: 'Para o sucesso do seu tratamento',
      panel1: {
        description:
          'Para garantir o melhor resultado possível, é fundamental seguir os <1>cuidados pré-sessão</1> com atenção:',
        hydration:
          '<0>Hidratação:</0>	Mantenha a pele bem hidratada nos dias que antecedem a sessão.',
        hair: '<0>Aparar os pelos:</0> Realize a aparação dos pelos de 2 a 3 dias antes da sessão,	conforme orientação, com lâmina ou creme depilatório.',
        exposure:
          '<0>Evitar exposição solar:</0> Evite exposição ao sol por pelo menos 15 dias antes da sessão.',
      },
      schedule: {
        title: 'Agendamentos das suas sessões',
        text: 'A Vialaser está presente em todo o Brasil, e suas sessões podem ser realizadas em qualquer uma de nossas lojas, desde que previamente agendadas. Recomendamos marcar as sessões com antecedência para garantir seu horário e assegurar a continuidade do tratamento, alcançando os melhores resultados possíveis. Lembre-se de sempre agendar a próxima quando finalizar sua sessão.',
      },
      treatment: {
        title: 'Resultado Esperado ao Tratamento',
        text: 'Cada corpo responde de forma única ao tratamento, por isso a comunicação entre o cliente e a profissional é essencial. Informe sempre como está sendo o resultado das sessões para que as potências do equipamento possam ser ajustadas. Lembre-se de que o uso de medicamentos, tratamentos médicos e certas condições de saúde podem impactar diretamente os resultados do tratamento. Se tiver dúvidas, converse com a aplicadora da sua sessão.',
      },
    },
    secondSection: {
      title: 'Para um bom relacionamento',
      contract: {
        title: 'Assinatura do Contrato',
        text: 'A aquisição do produto é realizada por meio de um contrato, que será enviado para o e-mail fornecido e pode ser solicitado a qualquer momento. É importante ressaltar que o pagamento das parcelas é obrigatório, independentemente da realização das sessões.',
      },
      serasa: {
        title: 'Inscrição no SERASA',
        text: 'O compromisso assumido pelo contrato deve ser cumprido, independentemente da realização das sessões. O não cumprimento pode resultar na inscrição nos serviços de proteção ao crédito.',
      },
      cancellation: {
        title: 'Cancelamento do Contrato',
        text: 'O consumidor pode solicitar o cancelamento do contrato por qualquer meio de contato, seja presencial ou virtual. Após a solicitação, um e-mail será enviado para confirmar a identidade e a intenção de cancelamento, contendo um botão para finalizar o processo. Após a aprovação do cancelamento pelo consumidor, será enviado o distrato e os valores referentes ao rompimento contratual.',
      },
      clause: {
        title: 'Cláusula de Retenção de 30% do Valor Restante',
        text: 'O contrato prevê expressamente a retenção de 30% sobre o valor correspondente à parte não cumprida. O pagamento ou reembolso será determinado com base na quantia já paga pelo consumidor, levando em consideração as sessões não comparecidas e sessões canceladas.',
      },
    },
    success: {
      title: 'Obrigado pela confirmação!',
      text: 'Agradecemos por ler novamente nossos termos. Esperamos que nossa relação seja sempre a melhor possível!',
    },
    notConfirmed: {
      title: 'Confirmaremos os termos em outro momento',
      text: 'Não conseguimos confirmar os termos do seu contrato, porem está tudo bem. Podemos realizar a confirmação dos termos no seu próximo check-in!',
    },
  },
  actions: {
    back: 'Voltar',
    home: 'Voltar para o início',
    next: 'Próximo',
    understood: 'Entendi',
    continue: 'Continuar check-in',
  },
  biometry: {
    text: 'Por favor, use o <1>leitor biométrico</1> para confirmar que entendeu e concorda com os termos',
    use_code: 'Confirmar via código',
  },
  code: {
    title: 'Escolha por onde deseja receber o código de confirmação',
    use_biometry: 'Confirmar via biometria',
    choose_another: 'Escolher outra opção de envio',
    email: 'E-mail',
    placeholder: 'Código de validação',
    resend: 'Enviar novamente',
    resend_in: 'Enviar novamente em {{count}}s',
    valid_for_one: 'O código é válido apenas por {{count}} minuto.',
    valid_for_other: 'O código é válido apenas por {{count}} minutos.',
    time_up: '* O tempo acabou, envie outro código para poder continuar.',
    validate: 'Validar código',
  },
};
