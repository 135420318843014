import { useCallback, useState } from 'react';

import { makeHTTPProvider } from 'shared/infra/providers';
import { useUnit } from 'shared/presentation/contexts';

interface IVectisTermsConsentAggregate {
  consentiuTermosContrato: boolean;
  idCliente: number;
  email: string;
  foneCelular: string;
  whatsappValido: boolean;
  fluxoConsentimentoTermoContratoAtivo: boolean;
}

interface ITermsConsentAggregate {
  enabled: boolean;
  customer: {
    id: number;
    email: string;
    phone: string;
    validWhatsapp: boolean;
  };
}

export default function useTermsConfirmation() {
  const [termsData, setTermsData] = useState<ITermsConsentAggregate | null>(
    null,
  );
  const { unit } = useUnit();

  const shouldConsentToTerms = useCallback(
    async (params: { id?: number; document?: string }) => {
      if (!unit) return false;

      const aggregate =
        await makeHTTPProvider().get<IVectisTermsConsentAggregate>(
          'clientes/consentimentoTermosContrato',
          {
            params: {
              idCliente: params.id,
              cpf: params.document,
              idUnidade: unit.id,
            },
          },
        );

      const enabled =
        aggregate.fluxoConsentimentoTermoContratoAtivo &&
        !aggregate.consentiuTermosContrato;

      if (enabled) {
        setTermsData(buildTermsData(aggregate));
      }

      return enabled;
    },
    [unit],
  );

  return {
    shouldConsentToTerms,
    termsData,
    resetData: () => setTermsData(null),
  };
}

function buildTermsData({
  consentiuTermosContrato,
  fluxoConsentimentoTermoContratoAtivo,
  email,
  foneCelular,
  idCliente,
  whatsappValido,
}: IVectisTermsConsentAggregate): ITermsConsentAggregate {
  return {
    enabled: fluxoConsentimentoTermoContratoAtivo && !consentiuTermosContrato,
    customer: {
      id: idCliente,
      email,
      phone: foneCelular,
      validWhatsapp: whatsappValido,
    },
  };
}
