import React from 'react';

import { Button } from 'shared/presentation/components/molecules';
import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';

interface ISuccessProps {
  onContinue(): void;
}

const Success: React.FC<ISuccessProps> = ({ onContinue }) => {
  const { t } = useLocale('checkin');

  return (
    <S.Container>
      <h1>{t('termsReminder.content.success.title')}</h1>

      <p>{t('termsReminder.content.success.text')}</p>

      <Button variant="primary" onClick={onContinue}>
        <span>{t('termsReminder.actions.continue')}</span>
      </Button>
    </S.Container>
  );
};

export default Success;
