import styled, { css } from 'styled-components';

const StepContainer = styled.section`
  ${({ theme }) =>
    css`
      color: ${theme.palette.text.main};
      max-width: ${theme.layout.spacing(100)};

      display: flex;
      flex-direction: column;

      ${theme.layout.rowGap(4)}

      h1 {
        font-size: ${theme.typography.sizes.title2};
        font-weight: ${theme.typography.weight.bold};
        color: ${theme.palette.primary.dark};
        text-align: center;
      }

      > div {
        display: flex;
        flex-direction: column;

        ${theme.layout.rowGap(3)}

        h2 {
          font-size: ${theme.typography.sizes.title3};
          font-weight: ${theme.typography.weight.bold};
          text-align: center;
        }

        ul {
          display: flex;
          flex-direction: column;

          ${theme.layout.rowGap(2)}

          font-size: ${theme.typography.sizes.body1};
          font-weight: ${theme.typography.weight.regular};

          strong {
            font-weight: ${theme.typography.weight.bold};
          }

          > p {
            color: ${theme.palette.text.light};
            text-align: center;
          }

          li {
            display: flex;
            ${theme.layout.columnGap(2)}

            align-items: center;

            div {
              display: flex;
              flex-direction: column;

              ${theme.layout.rowGap(1)}
            }

            img {
              width: ${theme.layout.spacing(6)};
              height: ${theme.layout.spacing(6)};
              object-fit: cover;
              border-radius: 50%;
              flex-shrink: 0;

              &.bordered {
                border: 1px solid ${theme.palette.neutral[700]};
              }
            }

            span {
              font-size: ${theme.typography.sizes.body2};
            }
          }
        }
      }
    `}
`;

export default StepContainer;
