import React from 'react';

import { useLocale } from 'shared/presentation/contexts';

import StepContainer from '../StepContainer';
import clockImage from './assets/clock.jpg';
import skinImage from './assets/skin.jpg';

const SecondStep: React.FC = () => {
  const { t } = useLocale('checkin');

  return (
    <StepContainer>
      <h1>{t('termsReminder.content.title')}</h1>

      <div>
        <h2>{t('termsReminder.content.firstSection.title')}</h2>

        <ul>
          <li>
            <img src={clockImage} alt="" />

            <div>
              <p>
                <strong>
                  {t('termsReminder.content.firstSection.schedule.title')}
                </strong>
              </p>

              <span>
                {t('termsReminder.content.firstSection.schedule.text')}
              </span>
            </div>
          </li>

          <li>
            <img src={skinImage} alt="" />

            <div>
              <p>
                <strong>
                  {t('termsReminder.content.firstSection.treatment.title')}
                </strong>
              </p>

              <span>
                {t('termsReminder.content.firstSection.treatment.text')}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </StepContainer>
  );
};

export default SecondStep;
