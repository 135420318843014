import React, { useState } from 'react';

import { useModal } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { useLocale } from 'shared/presentation/contexts';

import { FirstStep, FourthStep, SecondStep, ThirdStep } from './components';
import * as S from './styles';

const STEPS = [FirstStep, SecondStep, ThirdStep, FourthStep];

const MAX_STEP = STEPS.length - 1;
const MIN_STEP = 0;

interface IStepsProps {
  onConfirm(): void;
}

const Steps: React.FC<IStepsProps> = ({ onConfirm }) => {
  const { t } = useLocale('checkin');
  const { t: transT } = useLocale('translations');
  const [step, setStep] = useState(MIN_STEP);
  const modal = useModal();

  const hasPreviousStep = step > MIN_STEP;
  const hasNextStep = step < MAX_STEP;

  const nextStep = () => setStep(step => Math.min(step + 1, MAX_STEP));
  const previousStep = () => setStep(step => Math.max(step - 1, MIN_STEP));

  const Step = STEPS[step];

  return (
    <S.Container>
      <Step />

      <footer>
        {hasPreviousStep ? (
          <Button onClick={previousStep}>
            <span>{t('termsReminder.actions.back')}</span>
          </Button>
        ) : (
          <Button onClick={modal.close}>
            <span>{transT('pages.checkin.no_biometry.close')}</span>
          </Button>
        )}

        {hasNextStep ? (
          <Button variant="primary" onClick={nextStep}>
            <span>{t('termsReminder.actions.next')}</span>
          </Button>
        ) : (
          <Button variant="primary" onClick={onConfirm}>
            <span>{t('termsReminder.actions.understood')}</span>
          </Button>
        )}
      </footer>
    </S.Container>
  );
};

export default Steps;
