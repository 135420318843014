import { useMutation } from 'react-query';

import { makeHTTPProvider } from 'shared/infra/providers';
import { useTotemInfo } from 'shared/presentation/contexts';

type TParams = {
  id?: number;
  document?: string;
  onSuccess(hasConsent: boolean): void;
};

export default function useConfirmTerms(params: TParams) {
  const { unitId } = useTotemInfo();

  const mutation = useMutation(
    (hasConsent: boolean) =>
      makeHTTPProvider().post(
        'clientes/consentimentoTermosContrato',
        {
          cpf: params.document,
          idCliente: params.id,
          idUnidade: unitId,
          consentiuTermosContrato: hasConsent,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ),
    {
      onSuccess: (_, hasConsent) => params.onSuccess(hasConsent),
    },
  );

  return {
    loading: mutation.isLoading,
    confirm: mutation.mutateAsync,
    error: mutation.error instanceof Error ? mutation.error.message : undefined,
  };
}
