import { useCallback, useEffect, useRef, useState } from 'react';

import { makeWebSocketProvider } from 'shared/infra/providers';
import { useLocale, useUnit } from 'shared/presentation/contexts';

const TIMEOUT = 30 /* seconds */ * 1000;

const EVENTS = [
  'BIOMETRIA_NAO_CADASTRADA',
  'BIOMETRIA_CONFIRMADA',
  'BIOMETRIA_NAO_CONFIRMADA',
] as const;

type TEvent = (typeof EVENTS)[number];

const checkBiometry = (customerId: number, unitId: number) => {
  const provider = makeWebSocketProvider({ unitId });

  try {
    return new Promise<TEvent>((resolve, reject) => {
      provider.emit('lerBiometriaCliente', {
        id: customerId,
        idUnidade: unitId,
      });

      const timeout = setTimeout(() => {
        reject(new Error('Operation timeouted'));
      }, TIMEOUT);

      provider.addEventListener<{
        evento: TEvent;
      }>('biometriaConfirmada', ({ evento }) => {
        clearTimeout(timeout);
        provider.removeEventListener('biometriaConfirmada');

        if (!EVENTS.includes(evento)) reject(new Error('Uknown response'));

        resolve(evento);
      });
    });
  } catch (error) {
    provider.removeEventListener('biometriaConfirmada');
    throw error;
  }
};

type TParams = { customerId: number; onSuccess(): void };

export default function useFetchBiometry(params: TParams) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { unit } = useUnit();

  const { t } = useLocale('translations');

  const onSuccess = useRef(params.onSuccess);
  useEffect(() => {
    onSuccess.current = params.onSuccess;
  }, [params.onSuccess]);

  const getBiometry = useCallback(async () => {
    if (!unit) return;
    setLoading(true);

    try {
      const confirmation = await checkBiometry(params.customerId, unit.id);

      if (confirmation === 'BIOMETRIA_NAO_CONFIRMADA') {
        setError(t('pages.checkin.update.wrong_fingerprint'));
        return setLoading(false);
      }

      if (confirmation === 'BIOMETRIA_NAO_CADASTRADA') {
        setError(t('pages.checkin.update.fingerprint_not_registered'));

        return setLoading(false);
      }

      onSuccess.current();
    } catch (error) {
      setError(t('pages.checkin.update.biometry_error'));
    } finally {
      setLoading(false);
    }
  }, [params.customerId, unit, t]);

  useEffect(() => {
    getBiometry();
  }, [getBiometry]);

  return {
    loading,
    error,
    getBiometry,
  };
}
