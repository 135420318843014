import React from 'react';

import { Animation, useModal } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { Trans, useLocale } from 'shared/presentation/contexts';

import biometry from './assets/biometry.json';
import { useBiometryWarningMessage, useFetchBiometry } from './hooks';
import * as S from './styles';

interface IConfirmationProps {
  error?: string;
  customerId: number;
  onFinish(hasConsent: boolean): void;
}

const Confirmation: React.FC<IConfirmationProps> = ({
  customerId,
  onFinish,
  error,
}) => {
  const { t } = useLocale('checkin');
  const { t: transT } = useLocale('translations');
  const modal = useModal();

  const biometryWarning = useBiometryWarningMessage();
  const controller = useFetchBiometry({
    customerId,
    onSuccess: () => onFinish(true),
    onError: () => onFinish(false),
  });

  const message = error || controller.error;
  const hasError = !!message && !controller.loading;

  return (
    <S.Container>
      <p>
        <Trans t={t} localeKey="termsReminder.biometry.text">
          {' '}
          <strong> </strong>{' '}
        </Trans>
      </p>

      <div>
        <Animation
          animation={{ data: biometry, width: 100, height: 180 }}
          loop
        />
      </div>

      {controller.loading && (
        <p>{transT('pages.checkin.update.reading_biometry')}</p>
      )}

      {!!controller.loading && !!biometryWarning.message && (
        <p className="warning">{biometryWarning.message}</p>
      )}

      {hasError && <p className="error">{message}</p>}

      <footer>
        {hasError && controller.event !== 'BIOMETRIA_NAO_CONFIRMADA' && (
          <>
            <Button variant="primary" onClick={() => controller.getBiometry()}>
              <span>{transT('components.modal.biometry_error.title')}</span>
            </Button>
          </>
        )}

        <Button variant="default" onClick={() => modal.close()}>
          <span>{transT('components.modal.actions.cancel')}</span>
        </Button>
      </footer>
    </S.Container>
  );
};

export default Confirmation;
