import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 360px;
    height: 360px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${theme.palette.neutral[0]};
    border-radius: 2rem;
  `}
`;
