import React, {
  ComponentRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {
  Confirmation,
  Loading,
  NotConfirmed,
  Steps,
  Success,
} from 'pages/Checkin/components/TermsConfirmationModal/components';

import { Modal } from 'shared/presentation/components/atoms';
import { useInteractionTimeout } from 'shared/presentation/hooks';

import { useConfirmTerms } from './hooks';

const TIMEOUT = 30;

type TState = 'STEPS' | 'CONFIRMATION' | 'SUCCESS' | 'NOT_CONFIRMED';

interface ITermsConfirmationModalRef {
  open(): void;
}

interface ITermsConfirmationModalProps {
  customer: {
    id: number;
    email: string;
    phone: string;
    validWhatsapp: boolean;
  };
  onReset(): void;
  onContinue(customerId: number): void;
}

const TermsConfirmationModal: React.ForwardRefRenderFunction<
  ITermsConfirmationModalRef,
  ITermsConfirmationModalProps
> = ({ customer, onReset, onContinue }, ref) => {
  const modalRef = useRef<ComponentRef<typeof Modal>>(null);
  const [state, setState] = useState<TState>('STEPS');

  const controller = useConfirmTerms({
    ...customer,
    onSuccess: hasConsent => setState(hasConsent ? 'SUCCESS' : 'NOT_CONFIRMED'),
  });

  const timer = useInteractionTimeout({
    timeout: TIMEOUT,
    onTimeUp: useCallback(() => {
      modalRef.current?.close();
      onReset();
    }, [onReset]),
  });

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setState('STEPS');
        timer.reset();
        modalRef.current?.open();
      },
    }),
    [timer],
  );

  const onModalClose = () => setState('STEPS');

  if (controller.loading) {
    return (
      <Modal ref={modalRef} initialOpen onClose={onModalClose}>
        <Loading />
      </Modal>
    );
  }

  if (state === 'SUCCESS') {
    return (
      <Modal ref={modalRef} initialOpen onClose={onModalClose}>
        <Success
          onContinue={() => {
            modalRef.current?.close();
            onContinue(customer.id);
          }}
        />
      </Modal>
    );
  }

  if (state === 'NOT_CONFIRMED') {
    return (
      <Modal ref={modalRef} initialOpen onClose={onModalClose}>
        <NotConfirmed
          onContinue={() => {
            modalRef.current?.close();
            onContinue(customer.id);
          }}
        />
      </Modal>
    );
  }

  if (state === 'CONFIRMATION') {
    return (
      <Modal ref={modalRef} initialOpen onClose={onModalClose}>
        <Confirmation
          error={controller.error}
          customerId={customer.id}
          onFinish={hasConsent => controller.confirm(hasConsent)}
        />
      </Modal>
    );
  }

  return (
    <Modal ref={modalRef} initialOpen onClose={onModalClose}>
      <Steps onConfirm={() => setState('CONFIRMATION')} />
    </Modal>
  );
};

export default forwardRef(TermsConfirmationModal);
