import axios, { AxiosError, AxiosInstance } from 'axios';

import { HTTPError } from 'shared/domain/errors';
import IHTTPProvider, {
  IRequestConfig,
} from 'shared/domain/providers/IHTTPProvider';
import { TLocale } from 'shared/domain/valueObjects';

const isAxiosError = (error: Error | AxiosError): error is AxiosError<any> =>
  (error as AxiosError).isAxiosError;

const FORBIDDEN_STATUS = 401;

class AxiosVectisHTTPError extends HTTPError {
  public message: string;
  public unauthorized: boolean;
  public response: unknown = null;

  constructor(error: Error | AxiosError) {
    super();

    const axiosError = isAxiosError(error);

    this.name = error.name;
    this.stack = error.stack;
    this.message = axiosError
      ? error.response?.data.message || error.message
      : error.message;
    this.unauthorized =
      axiosError && error.response?.status === FORBIDDEN_STATUS;
    if (axiosError) this.response = error.response?.data;
  }
}

const TENANCIES = {
  pt: process.env.REACT_APP_API_BRAZIL_URL,
  es: process.env.REACT_APP_API_PARAGUAY_URL,
  'es-CR': process.env.REACT_APP_API_COSTA_RICA_URL,
} as const;

export default class AxiosVectisHTTPProvider implements IHTTPProvider {
  private instance: AxiosInstance;

  public get domain() {
    return String(this.instance.defaults.baseURL);
  }

  constructor(locale: TLocale) {
    const baseURL = TENANCIES[locale];

    const VectisAPI = axios.create({
      baseURL,
      headers: {
        Authorization: `${process.env.REACT_APP_API_TOKEN}`,
        Language: locale,
      },
    });

    this.instance = VectisAPI;
  }

  public async delete<T = unknown>(
    path: string,
    config?: IRequestConfig,
  ): Promise<T> {
    try {
      const response = await this.instance.delete(path, config);
      return response.data;
    } catch (error: any) {
      throw new AxiosVectisHTTPError(error);
    }
  }

  public async get<T = unknown>(
    path: string,
    config?: IRequestConfig,
  ): Promise<T> {
    try {
      const response = await this.instance.get(path, config);
      return response.data;
    } catch (error: any) {
      throw new AxiosVectisHTTPError(error);
    }
  }

  public async patch<T = unknown>(
    path: string,
    body?: unknown,
    config?: IRequestConfig,
  ): Promise<T> {
    try {
      const response = await this.instance.patch(path, body, config);
      return response.data;
    } catch (error: any) {
      throw new AxiosVectisHTTPError(error);
    }
  }

  public async post<T = unknown>(
    path: string,
    body?: unknown,
    config?: IRequestConfig,
  ): Promise<T> {
    try {
      const response = await this.instance.post(path, body, config);
      return response.data;
    } catch (error: any) {
      throw new AxiosVectisHTTPError(error);
    }
  }

  public async put<T = unknown>(
    path: string,
    body?: unknown,
    config?: IRequestConfig,
  ): Promise<T> {
    try {
      const response = await this.instance.put(path, body, config);
      return response.data;
    } catch (error: any) {
      throw new AxiosVectisHTTPError(error);
    }
  }

  public setLocale(locale: TLocale): void {
    const baseURL = TENANCIES[locale];
    this.instance.defaults.baseURL = baseURL;
    this.instance.defaults.headers.Language = locale;
  }
}
