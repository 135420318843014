import React, { ComponentRef, useMemo, useRef } from 'react';

import { Modal } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { useLocale } from 'shared/presentation/contexts';

import banner from './assets/banner.png';
import * as S from './styles';

const BannerModal: React.FC = () => {
  const { t, language } = useLocale('translations');

  const modalRef = useRef<ComponentRef<typeof Modal>>(null);

  const isBannerOnCheckinAvailable = useMemo(
    () => language === 'pt',
    [language],
  );

  if (!isBannerOnCheckinAvailable) return null;

  return (
    <Modal ref={modalRef} initialOpen>
      <S.Container>
        <img src={banner} alt="" />

        <footer>
          <Button
            variant="primary"
            onClick={() => {
              modalRef.current?.close();
            }}
          >
            <span>{t('shared:components.alert.close' as any)}</span>
          </Button>
        </footer>
      </S.Container>
    </Modal>
  );
};

export default BannerModal;
