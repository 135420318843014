import io from 'socket.io-client';

import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import IWebSocketProvider from 'shared/domain/providers/IWebSocketProvider';

type TConstructor = {
  url: string;
  unitId?: number;
  httpProvider: IHTTPProvider;
};

export default class IOWebSocketProvider implements IWebSocketProvider {
  private connection: SocketIOClient.Socket;
  private unitId: number | undefined;
  private httpProvider: IHTTPProvider;

  private connectionBuilder: () => SocketIOClient.Socket;

  constructor({ url, unitId, httpProvider }: TConstructor) {
    this.connectionBuilder = () => {
      this.logEvent('connect', { unitId: this.unitId });
      const connection = io.connect(url, {
        reconnection: true,
        forceNew: true,
      });

      connection.on('disconnect', () =>
        this.logEvent('disconnect', { unitId: this.unitId }),
      );

      return connection;
    };
    this.unitId = unitId;
    this.httpProvider = httpProvider;
    this.connection = this.connectionBuilder();
  }

  public connect() {
    this.connection.close();
    this.connection = this.connectionBuilder();
  }

  public emit(event: string, data?: unknown) {
    this.connection.emit(event, data);

    this.logEvent(`emit:${event}`, data);
  }

  public addEventListener<T = unknown>(
    event: string,
    listener: (response: T) => void,
  ) {
    this.connection.on(event, (payload: unknown) => {
      const parsed =
        typeof payload === 'string' ? JSON.parse(payload) : payload;

      this.logEvent(`listen:${event}`, parsed);

      listener(parsed);
    });
  }

  public removeEventListener(event: string) {
    this.connection.removeEventListener(event);
  }

  public removeAllListeners() {
    this.connection.removeAllListeners();
  }

  public setUnit(id: number): void {
    this.unitId = id;
  }

  private logEvent(event: string, payload: unknown) {
    if (process.env.NODE_ENV === 'development') {
      return console.log({
        source: 'TOTEM',
        unit_id: this.unitId,
        event,
        payload: this.appendEnvToPayloadObject(payload),
      });
    }

    this.httpProvider.post(
      '/development',
      JSON.stringify({
        source: 'TOTEM',
        unit_id: this.unitId,
        event,
        payload: this.appendEnvToPayloadObject(payload),
      }),
    );
  }

  private appendEnvToPayloadObject(payload: unknown) {
    if (!payload) return {};

    if (typeof payload === 'string') return payload;

    return { ...payload, env: process.env.REACT_APP_API_ENV };
  }
}
