import React from 'react';

import { Spinner } from 'shared/presentation/components/atoms';
import { useTheme } from 'shared/presentation/contexts';

import * as S from './styles';

const Loading: React.FC = () => {
  const { theme } = useTheme();
  return (
    <S.Container>
      <Spinner color={theme.palette.primary.main} size={3} />
    </S.Container>
  );
};

export default Loading;
