import React from 'react';

import { Trans, useLocale } from 'shared/presentation/contexts';

import StepContainer from '../StepContainer';
import exposureImage from './assets/exposure.png';
import hairImage from './assets/hair.png';
import hydroImage from './assets/hydro.jpg';

const FirstStep: React.FC = () => {
  const { t } = useLocale('checkin');

  return (
    <StepContainer>
      <h1>{t('termsReminder.content.title')}</h1>

      <div>
        <h2>{t('termsReminder.content.firstSection.title')}</h2>

        <ul>
          <p>
            <Trans
              t={t}
              localeKey="termsReminder.content.firstSection.panel1.description"
            >
              {' '}
              <strong> </strong>{' '}
            </Trans>
          </p>

          <li>
            <img src={hydroImage} alt="" />

            <p>
              <Trans
                t={t}
                localeKey="termsReminder.content.firstSection.panel1.hydration"
              >
                <strong> </strong>{' '}
              </Trans>
            </p>
          </li>

          <li>
            <img src={hairImage} alt="" />

            <p>
              <Trans
                t={t}
                localeKey="termsReminder.content.firstSection.panel1.hair"
              >
                <strong> </strong>{' '}
              </Trans>
            </p>
          </li>

          <li>
            <img src={exposureImage} alt="" />

            <p>
              <Trans
                t={t}
                localeKey="termsReminder.content.firstSection.panel1.exposure"
              >
                <strong> </strong>{' '}
              </Trans>
            </p>
          </li>
        </ul>
      </div>
    </StepContainer>
  );
};

export default FirstStep;
