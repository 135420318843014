import ICheckinPubSub from 'shared/domain/pubsubs/ICheckinPubsub';
import { makeWebSocketProvider } from 'shared/infra/providers';

import LegacyTotemCheckinPubsub from './implementations/LegacyTotemCheckinPubsub';
import V1TotemCheckinPubsub from './implementations/V1TotemCheckinPubsub';

const PUBSUBS = {
  legacy: LegacyTotemCheckinPubsub,
  '1': V1TotemCheckinPubsub,
} as const;

type TType = keyof typeof PUBSUBS;

const instances = new Map<TType, ICheckinPubSub>();

export default function makeCheckinPubSub({
  type = 'legacy',
  unitId,
}: {
  type: TType | undefined;
  unitId?: number;
}) {
  if (!instances.has(type)) {
    const provider = makeWebSocketProvider({ unitId });

    const Implementation = PUBSUBS[type];

    instances.set(type, new Implementation(provider));
  }

  return instances.get(type) as ICheckinPubSub;
}
