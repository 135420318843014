import { useCallback, useEffect, useRef, useState } from 'react';

import { makeCustomerBiometryPubsub } from 'shared/infra/pubsubs';
import { useLocale, useTotemInfo } from 'shared/presentation/contexts';

const AUTO_RESET_TIMEOUT_MS = 10 * 1000;

export default function useBiometryWarningMessage() {
  const [warningMessage, setWarningMessage] = useState('');
  const timeoutRef = useRef<unknown>(); // Typescript is conflicting setTimeout return values
  const { unitId } = useTotemInfo();

  const { t } = useLocale('translations');

  useEffect(() => {
    const pubsub = makeCustomerBiometryPubsub({ unitId });

    const unsubscribe = pubsub.subscribeForBiometryErrorMessages(
      ({ event }) => {
        clearTimeout(timeoutRef.current as number);

        if (event === 'NOT_CONFIRMED') {
          setWarningMessage(t('pages.checkin.update.not_confirmed_warning'));
        }

        timeoutRef.current = setTimeout(
          () => setWarningMessage(''),
          AUTO_RESET_TIMEOUT_MS,
        );
      },
    );

    return () => {
      unsubscribe();
    };
  }, [t, unitId]);

  return {
    message: warningMessage,
    reset: useCallback(() => setWarningMessage(''), []),
  };
}
