import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${theme.layout.spacing(55)};

    padding: ${theme.layout.spacing(8)};
    ${theme.layout.rowGap(6)}

    background-color: ${theme.palette.neutral[50]};
    border-radius: 36px;

    text-align: center;

    p {
      font-size: ${theme.typography.sizes.body1};
      font-weight: ${theme.typography.weight.regular};
      color: ${theme.palette.text.main};

      strong {
        font-weight: ${theme.typography.weight.bold};
      }

      &.warning {
        color: ${theme.palette.warning.dark};
      }

      &.error {
        color: ${theme.palette.error.main};
      }
    }

    footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      ${theme.layout.rowGap(2)}

      > button {
        width: 100%;
      }
    }
  `}
`;
