import axios, { AxiosInstance } from 'axios';

import IHTTPProvider, {
  IRequestConfig,
} from 'shared/domain/providers/IHTTPProvider';

const DOMAIN = 'https://xx11fpzpaa.execute-api.sa-east-1.amazonaws.com';

export default class AxiosMetricsHTTPProvider implements IHTTPProvider {
  private instance: AxiosInstance;

  public get domain() {
    return String(this.instance.defaults.baseURL);
  }

  constructor(_: any) {
    const api = axios.create({
      baseURL: DOMAIN,
    });

    this.instance = api;
  }

  public async delete<T = unknown>(
    path: string,
    config?: IRequestConfig,
  ): Promise<T> {
    const response = await this.instance.delete(path, config);
    return response.data;
  }

  public async get<T = unknown>(
    path: string,
    config?: IRequestConfig,
  ): Promise<T> {
    const response = await this.instance.get(path, config);
    return response.data;
  }

  public async patch<T = unknown>(
    path: string,
    body?: unknown,
    config?: IRequestConfig,
  ): Promise<T> {
    const response = await this.instance.patch(path, body, config);
    return response.data;
  }

  public async post<T = unknown>(
    path: string,
    body?: unknown,
    config?: IRequestConfig,
  ): Promise<T> {
    const response = await this.instance.post(path, body, config);
    return response.data;
  }

  public async put<T = unknown>(
    path: string,
    body?: unknown,
    config?: IRequestConfig,
  ): Promise<T> {
    const response = await this.instance.put(path, body, config);
    return response.data;
  }

  public setLocale = () => undefined;
  public setHeader = () => undefined;
}
