import { useCallback, useEffect, useRef, useState } from 'react';

import {
  makeHTTPProvider,
  makeWebSocketProvider,
} from 'shared/infra/providers';
import { useLocale, useUnit } from 'shared/presentation/contexts';

const TIMEOUT = 30 /* seconds */ * 1000;

const EVENTS = [
  'BIOMETRIA_NAO_CADASTRADA',
  'BIOMETRIA_CONFIRMADA',
  'BIOMETRIA_NAO_CONFIRMADA',
  'DEDO_NAO_PRESENTE',
] as const;

type TEvent = (typeof EVENTS)[number];

type TEventHandler = { evento: TEvent };

const checkBiometry = (customerId: number, unitId: number) => {
  const provider = makeWebSocketProvider({ unitId });

  try {
    provider.connect();

    return new Promise<TEvent>((resolve, reject) => {
      provider.emit('lerBiometriaCliente', {
        id: customerId,
        idUnidade: unitId,
      });

      const timeout = setTimeout(() => {
        reject(new Error('Operation timeouted'));
      }, TIMEOUT);

      const eventHandler = ({ evento }: TEventHandler) => {
        clearTimeout(timeout);

        provider.removeEventListener('tentativaLeituraConfirmacao');
        provider.removeEventListener('biometriaConfirmada');

        if (!EVENTS.includes(evento)) reject(new Error('Uknown response'));
        resolve(evento);
      };

      provider.addEventListener<TEventHandler>(
        'biometriaConfirmada',
        eventHandler,
      );
      provider.addEventListener<TEventHandler>(
        'tentativaLeituraConfirmacao',
        eventHandler,
      );
    });
  } catch (error) {
    const payload: any = {
      env: process.env.REACT_APP_API_ENV,
    };
    if (error instanceof Error) payload.error = { ...error };

    provider.removeEventListener('biometriaConfirmada');
    provider.removeEventListener('tentativaLeituraConfirmacao');

    makeHTTPProvider({ source: 'METRICS' }).post(
      '/development',
      JSON.stringify({
        source: 'TOTEM',
        unit_id: unitId,
        event: 'erroBiometria',
        payload,
      }),
    );
    throw error;
  }
};

type TParams = {
  customerId: number;
  onSuccess(): void;
  onError(): void;
};

export default function useFetchBiometry(params: TParams) {
  const [event, setEvent] = useState<TEvent | null>(null);
  const [wrongAttemps, setWrongAttemps] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { unit } = useUnit();

  const { t } = useLocale('translations');

  const onSuccess = useRef(params.onSuccess);
  useEffect(() => {
    onSuccess.current = params.onSuccess;
  }, [params.onSuccess]);

  const onError = useRef(params.onError);
  useEffect(() => {
    onError.current = params.onError;
  }, [params.onError]);

  const getBiometry = useCallback(async () => {
    if (!unit) return;
    setLoading(true);

    try {
      const confirmation = await checkBiometry(params.customerId, unit.id);
      setEvent(confirmation);

      if (confirmation === 'DEDO_NAO_PRESENTE') {
        setError(t('pages.checkin.update.fingerprint_not_provided'));
        return setLoading(false);
      }

      if (confirmation === 'BIOMETRIA_NAO_CONFIRMADA') {
        setError(t('pages.checkin.update.wrong_fingerprint'));

        if (wrongAttemps > 1) onError.current();
        setWrongAttemps(current => current + 1);

        return setLoading(false);
      }

      if (confirmation === 'BIOMETRIA_NAO_CADASTRADA') {
        setError(t('pages.checkin.update.fingerprint_not_registered'));
        onError.current();

        return setLoading(false);
      }

      onSuccess.current();
    } catch (error) {
      setError(t('pages.checkin.update.biometry_error'));
    } finally {
      setLoading(false);
    }
  }, [params.customerId, wrongAttemps, unit, t]);

  useEffect(() => {
    getBiometry();
  }, [getBiometry]);

  return {
    loading,
    error,
    event,
    getBiometry,
  };
}
